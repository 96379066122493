<template>
<div class="header-box" :class="isShowHead?'on':''">
  <div class="header-main">
    <!--logo-->
    <div class="logo">
      <a href="/">
        <img src="~@/assets/logo.png" style="height: 41px;">
      </a>
    </div>
    <!--nav-->
    <div class="nav">
      <ul>
        <li v-for="(item,index) in headerNav" :key="index"
            :class="navCurrIndex == index?'on':''"
        @click="getNav(item,index)">
          {{item.name}}
        </li>
      </ul>
    </div>
  </div>
  <!--END!-->
</div>
</template>

<script>
export default {
  name: "Header",
  data(){
    return {
      isShowHead:false,
      navCurrIndex:0,
      headerNav:[
        {name:'首页',path:'/'},
        {name:'产品介绍',path:'/Cpjs/index'},
        {name:'品牌简介',path:'/Ppjj/index'},
        {name:'品牌动态',path:'/Ppdt/index'},
        {name:'加入我们',path:'/JoinUs/index'},
        {name:'联系我们',path:'/About/index'}
      ]
    }
  },
  mounted() {
    console.log(this.$route);
    this.navCurrIndex = this.$route.meta.index
    if(this.$route.name == "首页"){
      this.isShowHead = false
      window.addEventListener('scroll', this.handleScroll)
    } else {
      this.isShowHead = true
    }
  },
  methods: {
    //监听滚动
    handleScroll() {
      // console.log('监听滚动')
      console.log(this.$route);
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      if(top >= 64 && this.$route.name == '首页'){
        this.isShowHead = true;
      } else if(top < 64 && this.$route.name == '首页') {
        this.isShowHead = false;
      } else {
        this.isShowHead = true;
      }
    },
    //nav跳转
    getNav(item,index){
      if(item.name == "首页"){
        this.isShowHead = false
        window.addEventListener('scroll', this.handleScroll)
      } else {
        this.isShowHead = true
      }
      this.navCurrIndex = index
      this.$router.push(item.path)
    }

  }

}
</script>

<style scoped lang="less">
@font-face {
  font-family: sweispringsugarcjktc-semibold;
  src: url("~@/assets/fonts/sweispringsugarcjktc-semibold.ttf");
}
  .header-box{

    margin: auto;
    width: 100%;
    height: 64px;
    min-width: 1280px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background: #040000!important;

    .header-main{
      width: 85%;
      height: 100%;
      margin: auto;
      position: relative;
      //logo
      .logo {
        float: left;
        height: 64px;
        display: flex;
        align-items: center;
        a{
          text-decoration-line: none;
          height: 64px;
          display: flex;
          align-items: center;
        }
        img{
          width: 38px;
          margin-right: 9px;
        }
        .logo-name{
          color:#ffffff;
          list-style: none;
          margin: 0;
          padding: 0;
          li{
            font-family: "Coca";
            &:nth-of-type(1){
              font-size: 18px;
            }
            &:nth-of-type(2){
              font-size: 10px;
            }
          }
        }
      }
      //nav
      .nav{
        height: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        padding: 0;
        >ul{
          list-style: none;
          color:#fff;
          padding: 0;
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          li{
            margin: 0 40px;
            cursor: pointer;
            position: relative;
            /*width: 80px;*/
              height: 18px;
            text-align: center;
            font-family: sweispringsugarcjktc-semibold;

            font-size: 18px;
            color:#ffffff;
            white-space: nowrap;
              &:hover,&.on{
                font-family: "sweispringsugarcjktc-semibold";
                background-image: linear-gradient(
                        to right,
                        #d9ce32,
                        #d379bf,
                        #9326fb,
                        #5227ff
                );
                // background: linear-gradient(to right, #ff0000, #ffff00); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
                -webkit-background-clip: text;
                color: transparent;
                font-size: 18px;
font-weight: bold;
              }
          }
        }

      }
    }
  }
</style>
