<template>
  <div class="Ppdt-Body">
    <img class="Ppdt-Title" src="~@/assets/img/icon-pplm.png">
    <img class="Ppdt-banner" src="~@/assets/img/banner-1@2x.png">
    <img class="Ppdt-Title" src="~@/assets/img/icon-pphz.png">
    <img class="Ppdt-banner" src="~@/assets/img/banner-2@2x.png">
    <img class="Ppdt-Title" src="~@/assets/img/icon-pphz.png">
    <img class="Ppdt-banner" src="~@/assets/img/banner-3@2x.png">

  </div>
</template>
<script>

  export default {


  }
</script>
<style lang="less">
  .Ppdt-Body{
    width: 1200px;
    margin: 0 auto;
    padding-top: 15px;
    position: relative;
    &::-webkit-scrollbar{
      width: 0;
    }
    .Ppdt-Title{
      height: 48px;
      display: block;
      margin:80px auto 40px auto;
    }
    .Ppdt-banner{
      width: 100%;
      display: block;
      margin:0 auto;
    }
  }
</style>
