<template>
  <!--
    - @Author：zhangbo
    - @Develop：品牌介绍
  -->
  <div class="Ppjs-Body">
    <img class="Ppdt-Title" src="~@/assets/img/ppdw.png">
    <img class="Ppdt-banner" src="~@/assets/img/ppdw-banner@2x.png">
    <img class="Ppdt-Title" src="~@/assets/img/icon-ppfh.png">
    <img class="Ppdt-banner" src="~@/assets/img/ppfh-banner.png">
    <img class="Ppdt-Title" src="~@/assets/img/icon-ppln.png">
    <img class="Ppdt-banner" src="~@/assets/img/ppln-banner01.png">
    <img class="Ppdt-Title" src="~@/assets/img/icon-ppln.png">
    <img class="Ppdt-banner" src="~@/assets/img/ppln-banner02.png">

  </div>
</template>
<script>

  export default {


  }
</script>
<style lang="less">
  .Ppjs-Body{
    width: 1200px;
    margin: 0 auto;
    padding-top: 15px;
    position: relative;
    &::-webkit-scrollbar{
      width: 0;
    }
    .Ppdt-Title{
      height: 48px;
      display: block;
      margin:80px auto 40px auto;
    }
    .Ppdt-banner{
      width: 100%;
      display: block;
      margin:0 auto;
    }
  }
</style>
