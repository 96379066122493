<template>
  <!--
    - @Author：zhangbo
    - @Develop：产品介绍
  -->
  <div class="Cpjs-Body" ref="CpjsBody">
    <!--tab-->
    <div class="Cpjs-Tab">
      <span :class="CpjsTabIndex==0?'on':''" @click="CpjsTabIndex=0,scrollToTop()">眼影系列</span>
      <span :class="CpjsTabIndex==1?'on':''" @click="CpjsTabIndex=1,scrollToTop()">综合系列</span>
      <span :class="CpjsTabIndex==2?'on':''" @click="CpjsTabIndex=2,scrollToTop()">面彩系列</span>
    </div>
    <!--眼影系列-->
    <div v-show="CpjsTabIndex==0">
      <img class="Cpjs-Title" src="~@/assets/img/icon-yyxl.png">
      <img class="Cpjs-banner" src="~@/assets/img/yyxl-banner1@2x.png">
      <!--独白-野心-->
      <div class="db-yx-tab">
        <ul class="tab-menu">
          <li :class="tabIndex == 0?'on':''" @click="tabIndex = 0">独&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;白</li>
          <li :class="tabIndex == 1?'on':''" @click="tabIndex = 1">即&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;兴</li>
          <li :class="tabIndex == 2?'on':''" @click="tabIndex = 2">焦&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点</li>
          <li :class="tabIndex == 3?'on':''" @click="tabIndex = 3">亢&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;奋</li>
          <li :class="tabIndex == 4?'on':''" @click="tabIndex = 4">热&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浪</li>
          <li :class="tabIndex == 5?'on':''" @click="tabIndex = 5">偏&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;爱</li>
          <li :class="tabIndex == 6?'on':''" @click="tabIndex = 6">偷&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;心</li>
          <li :class="tabIndex == 7?'on':''" @click="tabIndex = 7">野&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;心</li>
        </ul>
        <img v-show="tabIndex == 0" src="~@/assets/img/cpjs-db.png">
        <img v-show="tabIndex == 1" src="~@/assets/img/cpjs-jx.png">
        <img v-show="tabIndex == 2" src="~@/assets/img/cpjs-jd.png">
        <img v-show="tabIndex == 3" src="~@/assets/img/cpjs-kf.png">
        <img v-show="tabIndex == 4" src="~@/assets/img/cpjs-rl.png">
        <img v-show="tabIndex == 5" src="~@/assets/img/cpjs-pa.png">
        <img v-show="tabIndex == 6" src="~@/assets/img/cpjs-tx.png">
        <img v-show="tabIndex == 7" src="~@/assets/img/cpjs-yx.png">
      </div>
      <img class="Cpjs-banner" src="~@/assets/img/cpjs-cxyq.png">
      <!--春袭-野趣-->
      <div class="db-yx-tab">
        <ul class="tab-menu tab-menu02">
          <li :class="tabIndex2 == 0?'on':''" @click="tabIndex2 = 0">春&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;袭</li>
          <li :class="tabIndex2 == 1?'on':''" @click="tabIndex2 = 1">野&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;趣</li>
        </ul>
        <img v-show="tabIndex2 == 0" src="~@/assets/img/cpjs-cx.png">
        <img v-show="tabIndex2 == 1" src="~@/assets/img/cpjs-yq.png">
      </div>
      <!--4色自组影盘-->
      <div class="carousel-box">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item >
            <img  src="~@/assets/img/lb-4s01.png">
          </el-carousel-item>
          <el-carousel-item >
            <img  src="~@/assets/img/lb-4s02.png">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--32色自组影盘-->
      <div class="carousel-box" style="margin-top: 40px;">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item >
            <img  src="~@/assets/img/lb-32se01.png">
          </el-carousel-item>
          <el-carousel-item >
            <img  src="~@/assets/img/lb-32se02.png">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--综合系列-->
    <div v-show="CpjsTabIndex==1">
      <img class="Cpjs-Title" src="~@/assets/img/icon-zhxl.png">
      <div class="carousel-box">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item >
            <img  src="~@/assets/img/zhxl-01.png">
          </el-carousel-item>
          <el-carousel-item >
            <img  src="~@/assets/img/mcxl-01.png">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--面彩系列-->
    <div v-show="CpjsTabIndex==2">
      <img class="Cpjs-Title" src="~@/assets/img/icon-mcxl.png">
      <div class="carousel-box">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item >
            <img  src="~@/assets/img/lb-32se03.png">
          </el-carousel-item>
          <el-carousel-item >
            <img  src="~@/assets/img/lb-4s03.png">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>


    <!--END!=========================================-->
  </div>
</template>
<script>
  export default {
      data(){
        return {
          tabIndex: 0,
          tabIndex2: 0,
          CpjsTabIndex: 0
        }
      },
    methods:{
      scrollToTop(){
        console.log("scrollToTop");
        console.log(this.$refs.CpjsBody);
        var mainBox = document.getElementById('mainBox')
        mainBox.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动
        });
      }
    }
  }
</script>
<style lang="less">
  @font-face {
    font-family: sweispringsugarcjktc-semibold;
    src: url("~@/assets/fonts/sweispringsugarcjktc-semibold.ttf");
  }

  .Cpjs-Tab{
    width: calc(100% - 15px);
    height: 54px;
    box-shadow:0px 10px 10px #E9E9E9;
    position: fixed;
    top:64px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;z-index: 1000;
    span{
      font-size: 18px;
      font-family: sweispringsugarcjktc-semibold;
      cursor: pointer;
      margin: 0 30px;
      &.on{
        font-family: "sweispringsugarcjktc-semibold";
        background-image: linear-gradient(
                to right,
                #d9ce32,
                #d379bf,
                #9326fb,
                #5227ff
        );
        // background: linear-gradient(to right, #ff0000, #ffff00); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
        -webkit-background-clip: text;
        color: transparent;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .Cpjs-Body{
    width: 1200px;
    margin:60px auto 0 auto;
    padding-top: 15px;
    position: relative;
    &::-webkit-scrollbar{
      width: 0;
    }
    .Cpjs-Title{
      height: 48px;
      display: block;
      margin:60px auto 40px auto;
    }
    .Cpjs-banner{
      width: 100%;
      display: block;
      margin:0 auto;
    }
    .db-yx-tab{
      width: 100%;
      height: 1330px;
      margin:45px auto;
      position: relative;
      .tab-menu{
        position: absolute;
        top:60px;
        left: -70px;
        li{
          list-style: none;
          font-family: sweispringsugarcjktc-semibold;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          font-weight: bold;
          border-top:3px solid transparent;
          &.on{
            color:#722A1D;
            border-top:3px solid #722A1D;
          }
        }
        &.tab-menu02{
          li{
            &.on{
              color:#C1D67C;
              border-top:3px solid #C1D67C;
            }
          }
        }
      }
      >img{
        width: 1000px;
        height: 100%;
        display: block;
        margin:0 auto;
      }

    }
    .carousel-box{
      width: 100%;
      height: 1200px;
      .el-carousel{
        height: 100%!important;
        .el-carousel__container{
          height: 100%!important;
          .el-carousel__item{
            height: 100%!important;
            >img{
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
        }
      }
      .el-carousel__indicators{
        display: none;
      }
      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
      }
      .el-carousel__arrow{
        border-radius: 0!important;
        top:1150px!important;
        transform: translateY(0);
      }
      .el-carousel__arrow--left{
        left: 1100px!important;
      }
    }
  }
</style>
