<template>
  <div class="JoinUs-Body">
    <img class="JoinUs-Title" src="~@/assets/img/icon-JoinUs.png">
    <div class="JoinUs-Main">
      <img  src="~@/assets/img/JoinUs-Text.png">
      <img  src="~@/assets/img/icon-JoinUs1.png">
    </div>
    <div class="JoinUs-Foot">简历投递邮箱：felix@cheeryep.com</div>
  </div>
</template>
<script>

  export default {


  }
</script>
<style lang="less">
  .JoinUs-Body{
    width: 1200px;
    margin: 0 auto;
    height: calc(100% - 67px);
    padding-top: 67px;
    overflow: auto;
    &::-webkit-scrollbar{
      width: 0;
    }
    .JoinUs-Title{
      height: 48px;
      display: block;
      margin:0 auto 40px auto;
    }
    .JoinUs-Main{
      width: 100%;
      height: 526px;
      margin:0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >img{
        &:nth-of-type(2){
          width: 730px;
        }
      }
    }
    .JoinUs-Foot{
      width: 100%;
      height:24px;
      text-align: center;
      color: #000000;
      font-size: 22px;
      font-weight: 500;
      margin-top: 25px;
    }
  }
</style>
