export default {
    /**
     * 卡片数据
     */
    cardNums: config => {
        console.log(config);
        var  cardNumsList  = [
            {name:'完成的',nums:'10000'},
            {name:'进行中',nums:'875'},
            {name:'延误的',nums:'612'}
        ]
        return {
            code: 20000,
            data: cardNumsList,
            message: ""
        }

    },
    //项目的维度展示
    setPrjData: config => {
        console.log(config);
        var  PrjData  = [
            {
                name:'广州树根互联智能仓储立库项目',
                list:[
                    {nums:'4052-1245-6485',status2:'2',status3:'3',status4:'2',status5:'2',status6:'3',status7:'3',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'2',status3:'1',status4:'2',status5:'2',status6:'3',status7:'3',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'1',status3:'3',status4:'',status5:'',status6:'1',status7:'3',status8:'1',status9:'',status10:''},
                ]
            },
            {
                name:'昌都红十字会',
                list:[
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                ]
            },
            {
                name:'项目计划',
                list:[
                    {nums:'4052-1245-6485',status2:'2',status3:'3',status4:'2',status5:'2',status6:'3',status7:'3',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'2',status3:'1',status4:'2',status5:'2',status6:'3',status7:'3',status8:'1',status9:'',status10:''},
                ]
            },
            {
                name:'天加看板',
                list:[
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                ]
            },
            {
                name:'华恒数字化平台',
                list:[
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                    {nums:'4052-1245-6485',status2:'1',status3:'',status4:'1',status5:'3',status6:'',status7:'',status8:'1',status9:'',status10:''},
                ]
            },
        ]
        return {
            code: 20000,
            data: PrjData,
            message: ""
        }

    },
    //部门和阶段的维度展示
    setEchartsData: config => {
        console.log(config);
        var  EchartsData  = {
            xAxis:['机械设计','电气设计','软件设计','计划','生产制造','安装调试','电气调试','软件调试','初验收','终验收'],
            bar01:[210,280,523,653,754,125,379,158,795,158],
            bar02:[122,233,131,436,398,345,567,298,485,125]
        }
        return {
            code: 20000,
            data: EchartsData,
            message: ""
        }

    },
}