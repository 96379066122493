import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/common.less'
Vue.config.productionTip = false
import axios from 'axios'
Vue.prototype.$axios = axios
import './mock'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '7Y2edyvXhPKBucoIK9Rxe2dvuvatlRjG'    //这个地方是官方提供的ak密钥
})
new Vue({
  render: h => h(App), router,
}).$mount('#app')
