import Mock from 'mockjs'
import ProjectPlanApi from './ProjectPlan'


// 设置200-2000毫秒延时请求数据
// Mock.setup({
//   timeout: '200-2000'
// })

// 卡片数据
Mock.mock(/\/ProjectPlan\/cardNums/, 'get', ProjectPlanApi.cardNums)
// 项目的维度展示
Mock.mock(/\/ProjectPlan\/setPrjData/, 'get', ProjectPlanApi.setPrjData)
// 部门和阶段的维度展示
Mock.mock(/\/ProjectPlan\/setEchartsData/, 'get', ProjectPlanApi.setEchartsData)


