<!--
  - @Author：zhangbo
  - @Develop：联系我们
-->
<template>
    <div class="About-Body">
        <div class="About-Main">
            <div class="About-Text">
                <div>南京十二钗化妆品有限公司</div>
                <div>地址：南京市秦淮中山东路482号纺织大厦</div>
                <div>邮箱：felix@cheeryep.com</div>
                <div>邮编：210000</div>
            </div>
            <div id="container" style="width: 100%;height: 100%;">
                <baidu-map class="bm-view" :center="{lng: 118.80869, lat: 32.046542}" :zoom="20" :scroll-wheel-zoom="true">
                    <bm-marker :position="{lng: 118.80869, lat: 32.046542}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                        <!--<bm-label content="南京十二钗化妆品有限公司" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -135, height: 30}"/>-->
                    </bm-marker>
                </baidu-map>
            </div>
        </div>
        <Nav></Nav>
    </div>
</template>
<script>
    import Nav from '@/components/Nav.vue'
    export default {
        components:{Nav},
        data () {
            return {
                map: null
            }
        },
        mounted() {
            this.initAMap();
        },
        // unmounted() {
        //     this.map?.destroy();118.80869,32.046542
        // },
        methods: {
            initAMap() {


            },
        },
    };
</script>
<style lang="less">
    .anchorBL{
        display: none;
    }
    .bm-view {
        width: 100%;
        height: 100%;
    }
    .About-Body {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-top: 67px;

        .About-Main {
            width: 100%;
            height: 536px;
            background: #F3F3F3;
            position: relative;

            .About-Text {
                position: absolute;
                bottom: 49px;
                left: 54px;
                z-index: 10;
                > div {
                    &:nth-of-type(1) {
                        font-size: 22px;
                        font-weight: 500;
                        color: #000;
                        margin-bottom: 20px;
                    }

                    font-size: 18px;
                    font-weight: 400;
                    color: #000;
                }
            }
        }
    }
</style>
