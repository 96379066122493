<template>
  <div class="Home-Body">
    <img class="Home-banner" src="~@/assets/img/icon-home1.png">
    <img class="Home-banner" src="~@/assets/img/icon-home2.png">
    <img class="Home-banner" src="~@/assets/img/icon-home3.png">
    <img class="Home-banner" src="~@/assets/img/icon-home4.png">

    <Nav></Nav>
  </div>
</template>
<script>
  import Nav from '@/components/Nav.vue'
  export default {
    components:{
      Nav
    },

  }
</script>
<style lang="less">
  .Home-Body{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    padding-top: 67px;
    &::-webkit-scrollbar{
      width: 0;
    }
    .Home-banner{
      width: 100%;
      display: block;
      margin:0 auto 30px auto;
    }
  }
</style>
