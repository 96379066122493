<template>
  <div class="app_container">
    <Header></Header>
    <div class="main-box" id="mainBox" :class="{'content-collapse':collapse}">
      <transition name="move" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
    import bus from "./bus";
    import Header from "./Header";
    export default {
        name: "admin",
        components:{
          Header,
        },
        data: function () {
            return {
                collapse:''
            }
        },
        created() {
            bus.$on("collapse", msg => {
                this.collapse = msg;
            });
        },
        methods: {}
    }
</script>

<style lang="less" scoped>
.app_container{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main-box{
    width: 100%;
    margin: 0 auto 20px auto;
    /*background: #F0F2F5;*/
   position: relative;
    overflow: auto;
    &.content-collapse {
        left: 65px;
    }
  height: calc(100% - 85px);
}
</style>
