import Vue from 'vue';
import VueRouter from 'vue-router';

import layout from "@/layout/auto.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {

    return originalPush.call(this, location).catch(err => err)

}



const routes = [
    {
        path: '/',
        redirect: '/Home/index'
    },
    {
        path: '/',
        name: "index",
        redirect: '/Home',
        component: () => import('@/layout/admin.vue'),
        meta: { title: 'admin' },
        children:[
            //首页******************************************************
            {
                path: '/Home',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "首页",
                        component: () => import('@/views/Home/index.vue'),
                        meta: { activeMenu: '/Home/index',index:0  },
                    }
                ]
            },
            //产品介绍******************************************************
            {
                path: '/Cpjs',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "产品介绍",
                        component: () => import('@/views/Cpjs/index.vue'),
                        meta: { activeMenu: '/Cpjs/index',index:1  },
                    }
                ]
            },
            //品牌简介******************************************************
            {
                path: '/Ppjj',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "品牌简介",
                        component: () => import('@/views/Ppjj/index.vue'),
                        meta: { activeMenu: '/Ppjj/index',index:2  },
                    }
                ]
            },
            //品牌动态******************************************************
            {
                path: '/Ppdt',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "品牌动态",
                        component: () => import('@/views/Ppdt/index.vue'),
                        meta: { activeMenu: '/Ppdt/index',index:3  },
                    }
                ]
            },
            //加入我们******************************************************
            {
                path: '/JoinUs',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "加入我们",
                        component: () => import('@/views/JoinUs/index.vue'),
                        meta: { activeMenu: '/About/index',index:4  },
                    }
                ]
            },
            //关于我们******************************************************
            {
                path: '/About',
                component: layout,
                children: [
                    {
                        path: 'index',
                        name: "关于我们",
                        component: () => import('@/views/About/index.vue'),
                        meta: { activeMenu: '/About/index',index:5  },
                    }
                ]
            },
        ]
    },
]
export default new VueRouter({
    base: process.env.BASE_URL,
    routes
})
