<!--
    - @Author：zhangbo
    - @Date：2024-06-18 22:47
    - @Develop：左侧导航
-->
<template>
    <div class="Nav-Body">
        <img src="~@/assets/img/icon-weixin.png">
        <img src="~@/assets/img/icon-weibo.png">
        <img src="~@/assets/img/icon-tianmao.png">
        <img src="~@/assets/img/icon-douyin.png">
        <img src="~@/assets/img/icon-xiaohongshu.png">

    </div>
</template>

<script>
    export default {
        name: "Nav"
    }
</script>

<style lang="less" scoped>
    .Nav-Body{
        width: 48px;
        position: absolute;
        top:67px;
        left:-88px;
        z-index: 100;
        >img{
            width: 48px;
            height: 48px;
            display: inline-block;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
</style>
